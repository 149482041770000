import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_client_only = _resolveComponent("client-only");
  return _openBlock(), _createBlock(_component_client_only, null, {
    default: _withCtx(() => [_createVNode(_component_el_select, _mergeProps({
      ref: "elSelectRef"
    }, _ctx.elSelectProps, {
      onRemoveTag: _cache[0] || (_cache[0] = $event => _ctx.$emit('remove-tag', $event)),
      onClear: _cache[1] || (_cache[1] = $event => _ctx.$emit('clear', $event)),
      onVisibleChange: _cache[2] || (_cache[2] = $event => _ctx.$emit('visible-change', $event)),
      onFocus: _cache[3] || (_cache[3] = $event => _ctx.$emit('focus', $event)),
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.$emit('update:modelValue', $event)),
      onChange: _cache[5] || (_cache[5] = $event => _ctx.$emit('change', $event))
    }), {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions || [], option => {
        return _openBlock(), _createBlock(_component_el_option, {
          key: option.value,
          label: option.label,
          disabled: option.disabled,
          value: option.value
        }, null, 8, ["label", "disabled", "value"]);
      }), 128)), _renderSlot(_ctx.$slots, "default")]),
      _: 3
    }, 16)]),
    _: 3
  });
}