/**
 * 地区
 */
export interface IAreasRes {
    id: number;
    name: string;
    code: string;
    'taxonomy_id'?: number | string;
    'parent_id'?: number | string;
    taxonomy?: {
        id?: number | string;
        'taxonomy_name'?: string;
        display?: string;
    };
    children?: IAreasRes[] | null;
}

/**
 * 获取验证码入参
 */
// 留资来源 -> 10: 商城留资; 110: 5天试用申请留资; 110: 美国买家数据库试用申请留资;
export enum TRIAL_FROM_TYPE_ENUM {
    trial = 100,
    dataTrial = 110,
}
export enum PHONE_FROM_TYPE_ENUM {
    shop = 10,
    trial = TRIAL_FROM_TYPE_ENUM.trial,
    dataTrial = TRIAL_FROM_TYPE_ENUM.dataTrial,
}
export interface IPhoneReq {
    phone: string;
    type?: PHONE_FROM_TYPE_ENUM;
}

/**
 * 立即入驻入参
 */
export interface ISubmitJoinusReq {
    company?: string;
    product?: string;
    phone: string;
    captcha: string;
    area: string;
}

/**
 * 国家
 */
export interface IOptionItem<Label = string | number, Value = any> {
    label: Label;
    value: Value;
    disabled?: boolean;
}

export interface ICountryItem {
    country: string;
    country_code: number;
}

export interface ISubmitPartnerReq {
    people?: string;
    company?: string;
    region?: string;
    cooperate?: string;
    phone?: string;
}

/**
 * 申请试用入参
 */
export interface ISubmitFreetrialReq {
    type: TRIAL_FROM_TYPE_ENUM;
    company: string;
    name: string;
    phone: string;
    captcha: string;
    email: string;
    area: string;
}
