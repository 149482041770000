import {nextTick, Ref, ref} from 'vue';
import {SHOW_MESSAGE_MODE} from './types';
import {fetchCaptcha} from '@/base/api/common';
import {fetchInquiryCaptcha} from '@/base/api/news';
import {PHONE_FROM_TYPE_ENUM} from '@/base/api/interface/common';
import {INQUIRY_TYPE_ENUM} from '@/base/api/interface/news';
import {showElMessage} from '@/base/utils/message';
import {scLog} from '@/base/log/sclog';

export const useCaptcha = (formRef: Ref, form: any, showMessageMode: SHOW_MESSAGE_MODE) => {
    const isCountDownDisabled = ref<boolean>(false);
    const handleCountDown = (data, fromType?: PHONE_FROM_TYPE_ENUM | INQUIRY_TYPE_ENUM) => {
        // 埋点 from 标识是什么表单获取验证码
        scLog('click', {
            elem: 'b2b-inquiry-captcha-click',
            from: (fromType || '') as string,
        });
        isCountDownDisabled.value = true;
        // 验证是否有手机号 及 手机号是否合法
        formRef.value.validateField('phone', (isValid: boolean, failedField): void => {
            if (!isValid) {
                // 如果是 toast 模式，则使用 toast 提示错误信息
                if (showMessageMode === SHOW_MESSAGE_MODE.toast) {
                    showElMessage({
                        type: 'error',
                        center: true,
                        message: failedField?.phone[0]?.message || '请输入正确的手机号',
                        duration: 3000,
                    });
                }
                nextTick(() => {
                    isCountDownDisabled.value = false;
                });

                // 埋点 from 标识是什么表单获取验证码
                scLog('click', {
                    elem: 'b2b-inquiry-captcha-valid-fail',
                    from: (fromType || '') as string,
                    ext: JSON.stringify(failedField || ''),
                });
            }
            else {
                getCaptcha();
            }
        });

        async function getCaptcha() {
            const phone = form.phone || '';
            const params = data.params || {};

            try {
                switch (fromType) {
                    // 资讯详情页询盘表单的验证码
                    case INQUIRY_TYPE_ENUM.newsDetail: {
                        await fetchInquiryCaptcha(Object.assign({}, {phone, type: fromType}, params));
                        break;
                    }
                    // 出海易留资表单的验证码
                    default: {
                        const type = fromType ?? PHONE_FROM_TYPE_ENUM.shop;
                        await fetchCaptcha(Object.assign({}, {phone, type}, params));
                    }
                }

                scLog('click', {
                    elem: 'b2b-inquiry-captcha-success',
                    from: (fromType || '') as string,
                });
            }
            catch (err) {
                isCountDownDisabled.value = false;

                scLog('click', {
                    elem: 'b2b-inquiry-captcha-fail',
                    from: (fromType || '') as string,
                    ext: JSON.stringify(err || ''),
                });
            }
        }
    };
    return {
        isCountDownDisabled,
        handleCountDown,
    };
};
