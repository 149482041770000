import { defineComponent, ref, computed, watch, toRefs } from 'vue';
;
export default defineComponent({
  name: 'CountDown',
  props: {
    text: {
      type: String,
      default: '获取验证码'
    },
    count: {
      type: Number,
      default: 60
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup(props, {
    emit
  }) {
    const {
      btnText,
      isDisabled,
      onCountDown,
      resetCountDown
    } = useCountDown(props);
    const handleClick = () => {
      if (isDisabled.value) {
        return false;
      }
      isDisabled.value = true;
      emit('click');
      // 倒计时
      onCountDown();
    };
    return {
      btnText,
      isDisabled,
      handleClick,
      resetCountDown
    };
  }
});
function useCountDown(props) {
  const {
    disabled
  } = toRefs(props);
  let currCount = ref(props.count);
  let isDisabled = ref(false);
  const btnText = computed(() => {
    let text = props.text;
    if (isDisabled.value) {
      text = `${currCount.value}s`;
    }
    return text;
  });
  let timer = null;
  const resetCountDown = () => {
    clearInterval(timer);
    currCount.value = props.count;
    isDisabled.value = false;
  };
  watch(disabled, val => {
    isDisabled.value = val;
    !val && resetCountDown();
  }, {
    immediate: true
  });
  const onCountDown = () => {
    timer = setInterval(() => {
      if (currCount.value <= 0) {
        resetCountDown();
        return;
      }
      currCount.value--;
    }, 1000);
  };
  return {
    isDisabled,
    btnText,
    currCount,
    onCountDown,
    resetCountDown
  };
}