import { reactive, defineComponent, computed, ref, unref, toRaw } from 'vue';
import { ElMessage } from 'element-plus';
import TToast from './Toast.vue';
import TForm from '@/components/TForm/index.vue';
import { getMaxStringRule, getPhoneRule } from '@/base/utils/formRules';
import { useAreaOptions } from '@/base/hooks/useAreaOptions';
import { fetchSubmitJoinus } from '@/base/api/common';
import { scLog } from '@/base/log/sclog';
export default defineComponent({
  name: 'ConsultingForm',
  components: {
    TForm,
    TToast
  },
  props: {
    size: {
      type: String,
      default: ''
    },
    closeToast: {
      type: Boolean,
      default: false
    },
    logtype: {
      type: String,
      default: ''
    }
  },
  setup(props, {
    emit
  }) {
    const formRef = ref(null);
    let isShowToast = ref(false);
    const formData = reactive({
      company: '',
      area: [],
      product: '',
      name: '',
      phone: '',
      captcha: ''
    });
    // 获取地区数据
    const {
      areaOptions,
      areaProps
    } = useAreaOptions();
    const formConfig = computed(() => [{
      label: '企业名称',
      prop: 'company',
      type: 'input',
      required: true,
      rules: [getMaxStringRule(100)]
    }, {
      label: '企业注册地',
      prop: 'area',
      type: 'cascader',
      required: true,
      cascaderOptions: toRaw(unref(areaOptions)),
      areaProps: toRaw(unref(areaProps))
    }, {
      label: '主营产品或服务',
      prop: 'product',
      type: 'input',
      required: true,
      rules: [getMaxStringRule(100)]
    }, {
      label: '联系人',
      prop: 'name',
      type: 'input',
      required: true,
      rules: [getMaxStringRule(100)]
    }, {
      label: '手机号',
      prop: 'phone',
      type: 'input',
      required: true,
      rules: [getPhoneRule()]
    }, {
      label: '验证码',
      prop: 'captcha',
      type: 'input',
      required: true
    }]);
    const handleSubmit = () => {
      var _formRef$value;
      scLog('click', {
        elem: props.logtype ? `${props.logtype}-inquiry-form-btn` : 'inquiry-form-btn'
      });
      (_formRef$value = formRef.value) === null || _formRef$value === void 0 || _formRef$value.validate(isValid => {
        if (!isValid) {
          // 验证失败
          return false;
        }
        // 发送入驻请求
        postSubmitJoinus();
        async function postSubmitJoinus() {
          const params = {
            ...formData,
            area: formData.area.join(',')
          };
          try {
            const res = await fetchSubmitJoinus(params);
            const {
              errno,
              errmsg
            } = res;
            if (errno === 0) {
              var _formRef$value2;
              if (!props.closeToast) {
                isShowToast.value = true;
                setTimeout(() => {
                  isShowToast.value = false;
                }, 2000);
              } else {
                emit('submit-form-success');
                ElMessage.success('提交成功');
              }
              // 清空表单
              (_formRef$value2 = formRef.value) === null || _formRef$value2 === void 0 || _formRef$value2.resetFields();
            } else {
              ElMessage.error(errmsg);
            }
          } catch (error) {}
        }
      });
    };
    return {
      formData,
      formConfig,
      formRef,
      isShowToast,
      // function
      handleSubmit
    };
  }
});