import {FormItemRule} from 'element-plus';
import {ITFormItemConfig} from './types';
import {getRequiredStringRule, getEmojiRule} from '@/base/utils/formRules';

export function getPlaceholder(formItemOption: ITFormItemConfig) {
    const {placeholder, type, label} = formItemOption;
    if (typeof placeholder === 'string') {
        return placeholder;
    }
    switch (type) {
        case 'cascader':
        case 'select':
            return `请选择${label}`;
        case 'input':
        case 'textarea':
        default:
            return `请输入${label}`;
    }
}

export function getRules(formItemOption: ITFormItemConfig): FormItemRule[] {
    const {required, rules, type, label} = formItemOption;
    let requireRule: FormItemRule[] | null[] = [];
    if (required) {
        switch (type) {
            case 'cascader':
            case 'select':
                requireRule = [{required: true, message: `请选择${label}`}];
                break;
            case 'input':
            case 'textarea':
                requireRule = [getRequiredStringRule(label), getEmojiRule()];
                break;
            default:
                requireRule = [getRequiredStringRule(label)];
        }
    }
    const result = [...requireRule, ...(rules instanceof Array ? rules : [rules])].filter(
        rule => !!rule
    ) as FormItemRule[];
    return result;
}
