import { computed, defineComponent, reactive, ref } from 'vue';
import VNodeRender from '../VNodeRender/index.vue';
import { getPlaceholder, getRules } from './controller';
import { SHOW_MESSAGE_MODE } from './types';
import TSelect from './components/TSelect.vue';
import { getValueByIndex } from './utils';
import { useCaptcha } from './useCaptcha';
import CountDown from '@/components/CountDown/Index.vue';
import { PHONE_FROM_TYPE_ENUM } from '@/base/api/interface/common';
export default defineComponent({
  name: 'TForm',
  components: {
    TSelect,
    VNodeRender,
    CountDown
  },
  props: {
    // 留资表单来源: 10 商城; 1 资讯详情页的询盘表单;
    fromType: {
      type: Number,
      default: PHONE_FROM_TYPE_ENUM.shop
    },
    // ElForm 原生 Props
    model: {
      type: Object,
      required: true
    },
    rules: Object,
    labelPosition: String,
    labelWidth: {
      type: [String, Number],
      default: ''
    },
    labelSuffix: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    inline: Boolean,
    inlineMessage: Boolean,
    statusIcon: Boolean,
    showMessage: {
      type: Boolean,
      default: true
    },
    // 校验错误信息的展示形式 'normal' | 'toast'
    showMessageMode: {
      type: String,
      default: SHOW_MESSAGE_MODE.normal
    },
    size: String,
    disabled: Boolean,
    validateOnRuleChange: {
      type: Boolean,
      default: true
    },
    hideRequiredAsterisk: {
      type: Boolean,
      default: false
    },
    requireAsteriskPosition: {
      type: String,
      default: 'left'
    },
    scrollToError: Boolean,
    // 扩展 Props
    itemConfig: {
      type: Array,
      required: true
    },
    gutter: {
      type: Number,
      default: 30
    },
    // label 用来填充 placeholder
    isLabelFillPlaceholder: {
      type: Boolean,
      default: false
    }
  },
  emits: ['validate', 'form-item-blur'],
  setup(props, {
    emit
  }) {
    const reactiveProps = reactive(props);
    const elFormRef = ref(null);
    const countDownRef = ref(null);
    // 过滤出 ElForm 的原生 Props
    const elFormProps = computed(() => {
      // 本组件内扩展的 Props
      const extendsPropsKey = ['itemConfig', 'gutter', 'hideLabel', 'isLabelFillPlaceholder'];
      const result = {};
      Object.keys(reactiveProps).forEach(key => {
        if (!extendsPropsKey.includes(key)) {
          result[key] = reactiveProps[key];
        }
      });
      return result;
    });
    const {
      isCountDownDisabled,
      handleCountDown
    } = useCaptcha(elFormRef, props.model, props.showMessageMode);
    // 转发 ElFrom 实例的方法
    const validate = (...args) => {
      var _elFormRef$value;
      return (_elFormRef$value = elFormRef.value) === null || _elFormRef$value === void 0 ? void 0 : _elFormRef$value.validate(...args);
    };
    const validateField = (...args) => {
      var _elFormRef$value2;
      return (_elFormRef$value2 = elFormRef.value) === null || _elFormRef$value2 === void 0 ? void 0 : _elFormRef$value2.validateField(...args);
    };
    const resetFields = (...args) => {
      var _elFormRef$value3;
      // 重置验证码倒计时
      // 这里 countDownRef 是一个数组，应该是因为此组件被嵌套在一个遍历节点里的原因
      if (Array.isArray(countDownRef.value)) {
        countDownRef.value.forEach(item => {
          item.resetCountDown();
        });
      } else {
        var _countDownRef$value;
        (_countDownRef$value = countDownRef.value) === null || _countDownRef$value === void 0 || _countDownRef$value.resetCountDown();
      }
      (_elFormRef$value3 = elFormRef.value) === null || _elFormRef$value3 === void 0 || _elFormRef$value3.resetFields(...args);
    };
    const scrollToField = (...args) => {
      var _elFormRef$value4;
      return (_elFormRef$value4 = elFormRef.value) === null || _elFormRef$value4 === void 0 ? void 0 : _elFormRef$value4.scrollToField(...args);
    };
    const clearValidate = (...args) => {
      var _elFormRef$value5;
      return (_elFormRef$value5 = elFormRef.value) === null || _elFormRef$value5 === void 0 ? void 0 : _elFormRef$value5.clearValidate(...args);
    };
    const getModelValue = item => {
      var _item$componentProps$, _item$componentProps;
      return (_item$componentProps$ = (_item$componentProps = item.componentProps) === null || _item$componentProps === void 0 ? void 0 : _item$componentProps.modelValue) !== null && _item$componentProps$ !== void 0 ? _item$componentProps$ : props.model[item.prop];
    };
    const getUpdateModelValueMethods = item => {
      var _item$componentProps2;
      if (typeof ((_item$componentProps2 = item.componentProps) === null || _item$componentProps2 === void 0 ? void 0 : _item$componentProps2['onUpdate:modelValue']) === 'function') {
        return item.componentProps['onUpdate:modelValue'];
      }
      return val => props.model[item.prop] = val;
    };
    const getPlaceholderMethods = item => {
      // 先判断是否是使用 label 作为 placeholder
      return props.isLabelFillPlaceholder ? item.label : getPlaceholder(item);
    };
    const handleBlur = (item, val) => {
      emit('form-item-blur', item, val);
    };
    return {
      // Data
      elFormProps,
      elFormRef,
      isCountDownDisabled,
      handleCountDown,
      countDownRef,
      // Methods
      getPlaceholderMethods,
      getRules,
      getValueByIndex,
      getModelValue,
      getUpdateModelValueMethods,
      // ElForm Methods
      validate,
      validateField,
      resetFields,
      scrollToField,
      clearValidate,
      handleBlur
    };
  }
});