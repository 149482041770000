export function getValueByIndex(obj: {[key: string]: any}, indexStr: string) {
    const keys = indexStr.split('.');
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let result: any;
    keys.forEach(key => {
        const numberedKey = Number(key);
        result = obj[isNaN(numberedKey) ? key : numberedKey];
    });
    return result;
}
