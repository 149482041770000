/**
 * @file 基于 ElSelect 组件的封装，扩展以下功能：
 * - 可以通过 options 属性传入选项
 * - 可以通过传入一个 fetchMethod 来自动从远程拉取选项
 */
import { defineComponent, reactive, ref, computed, watch, onMounted } from 'vue';
export const isValidComponentSize = val => ['', 'large', 'default', 'small'].includes(val);
export default defineComponent({
  name: 'TSelect',
  props: {
    // ElSelect 原生树形
    name: String,
    id: String,
    modelValue: [Array, String, Number, Boolean, Object],
    autocomplete: {
      type: String,
      default: 'off'
    },
    automaticDropdown: Boolean,
    size: {
      type: String,
      validator: isValidComponentSize
    },
    disabled: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    allowCreate: Boolean,
    loading: Boolean,
    popperClass: {
      type: String,
      default: ''
    },
    remote: Boolean,
    loadingText: String,
    noMatchText: String,
    noDataText: String,
    remoteMethod: Function,
    filterMethod: Function,
    multiple: Boolean,
    multipleLimit: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String
    },
    defaultFirstOption: Boolean,
    reserveKeyword: Boolean,
    valueKey: {
      type: String,
      default: 'value'
    },
    collapseTags: Boolean,
    popperAppendToBody: {
      type: Boolean,
      default: true
    },
    clearIcon: {
      type: String,
      default: 'el-icon-circle-close'
    },
    // 扩展属性
    // 选择器选项配置（可以传入一个方法，返回 Promise 对象来从远程拉取选项）
    options: {
      type: [Array, Function],
      default: () => []
    }
  },
  emits: ['update:modelValue', 'change', 'remove-tag', 'clear', 'visible-change', 'focus', 'blur'],
  setup(props) {
    const reactiveProps = reactive(props);
    const elSelectRef = ref(null);
    const selectOptions = ref(reactiveProps.options instanceof Array ? reactiveProps.options : []);
    // 过滤出 ElSelect 的原生 Props
    const elSelectProps = computed(() => {
      // 本组件内扩展的 Props
      const extendsPropsKey = ['options'];
      const result = {};
      Object.keys(reactiveProps).forEach(key => {
        if (!(key in extendsPropsKey)) {
          result[key] = reactiveProps[key];
        }
      });
      return result;
    });
    // 同步修改 options
    watch(() => reactiveProps.options, newVal => {
      if (newVal instanceof Array) {
        selectOptions.value = newVal;
      }
    });
    onMounted(async () => {
      if (typeof reactiveProps.options === 'function') {
        const res = await reactiveProps.options();
        if (res instanceof Array) {
          selectOptions.value = res;
        }
      }
    });
    return {
      elSelectRef,
      elSelectProps,
      selectOptions
    };
  }
});