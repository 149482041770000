/**
 * @file 生成各种表单校验规则
 */

import {getAbsStrLen} from '@baidu/trade-util';
import {FormItemRule} from 'element-plus';
import {getTrimmedString} from '.';

// http://emailregex.com/
// eslint-disable-next-line max-len
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;

// eslint-disable-next-line max-len
export const httpUrlRegExp = /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*($|\/)/;

export const phoneRgeExp = /^1\d{10}$/;

// 身份证正则
export const idCardRgeExp = /^(\d{18,18}|\d{15,15}|\d{17,17}x)$/;

// 统一社会信用代码正则（支持 18 和 15 位）
export const socialCodeRegExp = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;

// emoji 正则
// eslint-disable-next-line max-len
export const emojiRegExp = /^[^\u{1F601}-\u{1F64F}\u{2702}-\u{27B0}\u{1F680}-\u{1F6C0}\u{1F170}-\u{1F251}\u{1F600}-\u{1F636}\u{1F681}-\u{1F6C5}\u{1F30D}-\u{1F567}]*$/gu;

/**
 * 获取必填字符串校验规则
 * @param itemName 该表单项的名称
 * @param resetRuleOption 复写表单规则
 */
export function getRequiredStringRule(itemName: string = '该项', resetRuleOption?: FormItemRule): FormItemRule {
    return {
        type: 'string',
        required: true,
        message: `请输入${itemName}`,
        transform: getTrimmedString,
        ...resetRuleOption,
    };
}

/**
 * 获取最大字符数的校验规则
 * @param maxLength 最大字符数
 * @param resetRuleOption 复写表单规则
 */
export function getMaxStringRule(maxLength: number, resetRuleOption?: FormItemRule): FormItemRule {
    return {type: 'string', max: maxLength, message: `最多输入 ${maxLength} 个字符`, ...resetRuleOption};
}

/**
 * 获取最大字符数的校验规则
 * @param maxLength 最大字符数
 * @param resetRuleOption 复写表单规则
 */
export function getMaxCharStringRule(maxLength: number, resetRuleOption?: FormItemRule): FormItemRule {
    return {
        validator: (rule, value, callback) => {
            // 计算字节长度
            const charLength = getAbsStrLen(value);
            if (charLength <= maxLength) {
                callback();
            }
            else {
                callback(`最多输入 ${maxLength} 个字符`);
            }
        },
        ...resetRuleOption,
    };
}

/**
 * 获取手机号格式校验规则
 * @param resetRuleOption 复写表单规则
 */
export function getPhoneRule(resetRuleOption?: FormItemRule): FormItemRule {
    return {pattern: phoneRgeExp, message: '请输入正确的手机号格式', ...resetRuleOption};
}

/**
 * 获取邮箱校验规则
 * @param resetRuleOption 复写表单规则
 */
export function getEmailRule(resetRuleOption?: FormItemRule): FormItemRule {
    return {type: 'email', message: '请输入正确的邮箱格式', ...resetRuleOption};
}

/**
 * 获取邮箱校验规则
 * @param resetRuleOption 复写表单规则
 */
export function getHTTPUrlRule(resetRuleOption?: FormItemRule): FormItemRule {
    return {
        pattern: httpUrlRegExp,
        message: '请输入正确的网站格式',
        ...resetRuleOption,
    };
}

/**
 * 获取身份证格式校验规则
 * @param resetRuleOption 复写表单规则
 */
export function getIdCardRule(resetRuleOption?: FormItemRule): FormItemRule {
    return {pattern: idCardRgeExp, message: '请输入正确的身份证号码格式', ...resetRuleOption};
}

/**
 * 获取统一社会信用代码
 * @param resetRuleOption 复写表单规则
 */
export function getWebSiteRule(resetRuleOption?: FormItemRule): FormItemRule {
    return {pattern: socialCodeRegExp, message: '请输入正确的身份证号码格式', ...resetRuleOption};
}

/**
 * 获取字符串中的emoji
 */
export function getEmojiRule(resetRuleOption?: FormItemRule): FormItemRule {
    return {pattern: emojiRegExp, message: '内容含有emoji表情，暂不支持，请修改', ...resetRuleOption};
}
