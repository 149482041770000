import {reactive, onMounted, toRefs} from 'vue';
import {IAreasRes} from '@/base/api/interface/common';
import {fetchAreas} from '@/base/api/common';

export interface IAreaState {
    areaOptions: any[];
    areaProps: {
        label: string;
        value: string;
    };
}

export const useAreaOptions = () => {
    const state = reactive<IAreaState>({
        areaOptions: [],
        areaProps: {
            label: 'name',
            value: 'code',
        },
    });

    const getAreas = async () => {
        try {
            const res = await fetchAreas();
            const {errno, data} = res;

            if (errno === 0) {
                // 把 children 的值 [] -> null;
                const filterAreaOptions = (list: IAreasRes[]) => {
                    list.forEach(item => {
                        if (item.children?.length) {
                            filterAreaOptions(item.children);
                            return;
                        }

                        item.children = null;
                    });
                };
                filterAreaOptions(data.area);
                state.areaOptions = data.area;
            }
            else {
                throw new Error('');
            }
        }
        catch (error) {
            // TODO 接口失败
        }
    };

    onMounted(() => {
        getAreas();
    });

    return {
        ...toRefs(state),
    };
};